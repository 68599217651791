import axios from 'axios'
import { storeToRefs } from 'pinia'
import { DialogProgrammatic as Dialog } from 'buefy'
import { v4 as uuidv4 } from 'uuid'
import { config } from '@/helpers/config'
import { api } from '@/services/api'
import { i18n } from '@/plugins/i18n'
import { useUserStore } from '../stores/user'
import { useAppStore } from '../stores/app'

export {
  getDocument,
  getGenericData,
  uploadDocument,
  uploadDocumentNew,
  downloadFile,
  downloadFileNew,
  downloadFilePopup,
  externalUploadDocument,
  getCompareDocument,
  deleteDocument
}

async function getDocument(form) {
  const apiResponse = await api.post('get-document-binary', form)
  return apiResponse
}

async function getGenericData(uri, data) {
  const apiResponse = await api.post(uri, data)
  return apiResponse
}

async function deleteDocument(document, row) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { getAccessToken } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  const headers = {
    headers: {
      Authorization: `Bearer ${getAccessToken.value}`
    }
  }

  const data = {
    hash: document.hash,
    client_key: clientKey.value
  }
  Dialog.confirm({
    title: i18n.t('remove-document-title'),
    message: `${i18n.t('remove-document-confirm-message')}`,
    type: 'is-danger',
    hasIcon: true,
    onCancel: () => {},
    onConfirm: () => {
      const apiResponse = api
        .post('delete-file', data, headers)
        .then(response => {
          if (response.code === 200) {
            const index = this.files.findIndex(obj => {
              return obj.id === document.id
            })
            this.files.splice(index, 1)
          }
        })
      this.isCardModalActive = false
      return apiResponse
    }
  })
}

async function downloadFileNew(endpoint, data = {}) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { getAccessToken } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${getAccessToken.value}`
    },
    responseType: 'blob'
  }

  if (i18n.locale) {
    data.locale = i18n.locale
  }

  const apiResponse = await axios.post(
    `${config.ApiUrl}${endpoint}?client_key=${clientKey.value}`,
    data,
    axiosConfig
  )

  const blobAux = new Blob([apiResponse.data], {
    type: apiResponse.headers['content-type']
  })

  try {
    const blobResponse = JSON.parse(await blobAux.text())
    return blobResponse
  } catch (error) {}

  return blobAux
}

async function downloadFile(location) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { getAccessToken } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${getAccessToken.value}`
    },
    responseType: 'blob'
  }

  const apiResponse = await axios.post(
    `${config.ApiUrl}download-file`,
    { document_location: location, client_key: clientKey.value },
    axiosConfig
  )

  return apiResponse
}

async function getCompareDocument(location) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { getAccessToken } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  const params = {
    document_location: location,
    base64: true,
    client_key: clientKey.value
  }

  const APIResponse = await api.post('download-file', params)

  if (APIResponse.code === 200) {
    return APIResponse
  }
}

function downloadFilePopup(fileUrl, fileName) {
  const a = document.createElement('a')
  a.href = fileUrl
  a.setAttribute('download', fileName)
  a.click()
}

async function uploadDocument(files, model, itemId, typeId, documentTypeId) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { getAccessToken } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  let endpoint = `${config.ApiUrl}upload-documents?client_key=${clientKey.value}&model=${model}&model_item_id=${itemId}`
  if (typeId) {
    endpoint += `&type_id=${typeId}`
  }
  if (documentTypeId) {
    endpoint += `&document_type_id=${documentTypeId}`
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${getAccessToken.value}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  const toReturn = await axios
    .post(endpoint, files.append, axiosConfig)
    .then(response => {
      if (
        response.data.actions.documents_upload.route_response.http_code === 400
      ) {
        throw response.data.actions.documents_upload.route_response.content
      }
      return response
    })
    .catch(error => {
      throw error
    })

  return toReturn
}

async function uploadDocumentNew(files, modelItemId, params) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { getAccessToken } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  const paramsString = Object.entries(params)
    .map(([key, v]) => {
      return `${key}=${v}`
    })
    .join('&')

  let endpoint = `${config.ApiUrl}upload-documents?client_key=${clientKey.value}&model_item_id=${modelItemId}&${paramsString}`

  const hash = uuidv4()
  endpoint += `&hash=${hash}`

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${getAccessToken.value}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  const toReturn = await axios
    .post(endpoint, files.append, axiosConfig)
    .then(response => {
      if (
        response.data.actions.documents_upload.route_response.http_code === 400
      ) {
        throw response.data.actions.documents_upload.route_response.content
      }
      return response
    })
    .catch(error => {
      throw error
    })

  return toReturn
}

async function externalUploadDocument(files, model, itemId, documentTypeId) {
  const userStore = useUserStore()
  const appStore = useAppStore()
  const { getAccessToken } = storeToRefs(userStore)
  const { clientKey } = storeToRefs(appStore)

  let endpoint = `${config.ApiUrl}external-upload-documents?client_key=${clientKey.value}&model=${model}&model_item_id=${itemId}`
  if (documentTypeId) {
    endpoint += `&document_type_id=${documentTypeId}`
  }
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${getAccessToken.value}`,
      'Content-Type': 'multipart/form-data'
    }
  }

  const toReturn = await axios
    .post(endpoint, files.append, axiosConfig)
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })

  return toReturn
}
