import list from './list'
import {
  getDocument,
  downloadFile,
  downloadFilePopup,
  uploadDocumentNew
} from '@/services/document'
import { useLayoutStore } from '@/stores/layout'
import { api } from '@/services/api'

export default {
  init(view) {
    const tabs = view.modules[this.name].uses.tabs.map(tab =>
      tab.view.type !== 'list'
        ? {
            ...tab,
            disabled: tab.view.props.disabled || false,
            view: {
              ...tab.view,
              component: 'DynamicForm',
              props: {
                handleTabConditionsUrl:
                  tab.view.props.endPoints?.handleTabConditions,
                conditionExpression: tab.view.props.conditionExpression,
                res: null,
                model: {},
                params: {
                  title: tab.name,
                  forms: [tab.view.form]
                },
                handles: {
                  api: view.api,
                  onCreate: _this =>
                    view.api({
                      endPoint: tab.view.flow || 'authenticated-forms',
                      method: 'post',
                      body: {
                        form: tab.view.form,
                        id: view.$route.params.id
                      },
                      callback: res => {
                        res = res.actions
                          ? res.actions[Object.keys(res.actions)[0]]
                              .route_response.content
                          : res

                        _this.form.type = res.form.form_type
                        view.model[this.name].model = res.form.form_model

                        _this.formBuilder({
                          formDependency: tab.view.form,
                          runDependencyEndpoint:
                            tab.view.props.endPoints?.runDependency,
                          ...res,
                          fields: res.form.fields,
                          hideSubmitButton: res.form.hide_submit_button
                        })

                        _this.form.buttons = res.form.buttons

                        if (res.form.warnings) {
                          _this.form.warnings = res.form.warnings
                        }
                      }
                    }),

                  formButtonClick: async (_this, button) => {
                    const buttonProps = JSON.parse(button.props)
                    let actionCompleted = false

                    if (buttonProps.aditionalParams) {
                      view.params[this.name].aditionalParams =
                        buttonProps.aditionalParams
                    }

                    const buttonCallback = () => {
                      switch (true) {
                        case button.is_submit === 1:
                          if (buttonProps.redirectAfterSubmit) {
                            view.params[this.name].redirectAfterSubmit =
                              buttonProps.redirectAfterSubmit
                          }

                          view.handles[this.name].onSubmit(_this)
                          break
                        case button.value === '$back':
                          view.$router.back()
                          break
                        case button.value === '$next':
                          view.handles[this.name].goToNextTab()
                          break
                        default:
                          break
                      }

                      actionCompleted = true
                    }

                    const functionalities = {
                      dialog: () => {
                        return new Promise((resolve, reject) => {
                          view.dialog({
                            type: buttonProps.dialog.type || 'is-primary',
                            hasIcon: true,
                            title: view.$t(buttonProps.dialog.title),
                            message: view.$t(buttonProps.dialog.message),
                            onCancel: () => {},
                            onConfirm: () => {
                              buttonCallback()
                              resolve()
                            }
                          })
                        })
                      }
                    }

                    for (const prop of Object.keys(buttonProps)) {
                      if (!Object.keys(functionalities).includes(prop)) continue

                      await functionalities[prop](button)
                    }

                    if (actionCompleted === false) buttonCallback()
                  },
                  openField: () => ({
                    docmodal: (col, row) => view.modal()
                  }),
                  onFieldClick: (parent, field, index = null) => {
                    const formModel =
                      field.properties.model ?? parent.form.model.model

                    const modelItemId =
                      parent.form.model.id ??
                      parent.form.dataset?.id ??
                      parent.form.model[`${formModel}.id`]

                    let hub = {
                      docmodal: () => {
                        view.api({
                          module: this,
                          method: 'post',
                          endPoint: 'get-document-list',
                          body: {
                            form:
                              field.properties.docmodal_form ??
                              'document-list-modal',
                            form_model: formModel,
                            model_item_id: modelItemId,
                            list_form: true
                          },
                          callback: async res => {
                            const data =
                              res.actions
                                .get_document_list_by_form_model_and_model_item_id
                                .route_response.content

                            const columns = data.form.fields.map(f => ({
                              name: f.field_name,
                              field: f.field_name,
                              label: f.field_label,
                              type: f.field_type,
                              id: f.id
                            }))

                            columns.push(
                              {
                                name: 'id',
                                field: 'id',
                                label: view.$t('docmodal-document-download'),
                                type: 'text',
                                render: {
                                  field: 'id',
                                  content: [
                                    {
                                      if: () => true,
                                      sequency: 1,
                                      component: 'b-button',
                                      content: {
                                        element: 'b-icon',
                                        title: 'Download',
                                        icon: 'download'
                                      },
                                      class:
                                        'is-primary is-small rounded-icons',
                                      onClick: ({ row }) => {
                                        const data = { hash: row.hash }
                                        getDocument(data).then(response => {
                                          const aux =
                                            response.data.actions
                                              .get_document_binary
                                              .route_response.content
                                          const mime =
                                            response.data.actions
                                              .get_document_binary
                                              .route_response.content.mimetype
                                          downloadFile(aux.location).then(
                                            response => {
                                              const blobAux = new Blob(
                                                [response.data],
                                                {
                                                  type: mime
                                                }
                                              )
                                              downloadFilePopup(
                                                window.URL.createObjectURL(
                                                  blobAux
                                                ),
                                                aux.real_name
                                              )
                                            }
                                          )
                                        })
                                      }
                                    }
                                  ]
                                }
                              },
                              {
                                name: 'id',
                                label: view.$t('docmodal-document-delete'),
                                render: {
                                  field: 'id',
                                  content: [
                                    {
                                      if: ({ row }) => row.can_delete,
                                      sequency: 1,
                                      component: 'b-button',
                                      content: {
                                        element: 'b-icon',
                                        title: 'Delete',
                                        icon: 'delete'
                                      },
                                      class:
                                        'is-primary is-small rounded-icons',
                                      onClick: ({ _this, row }) => {
                                        view.dialog({
                                          type: 'is-danger',
                                          hasIcon: true,
                                          title: view.$t(
                                            'remove-document-title'
                                          ),
                                          message: view.$t(
                                            'remove-document-confirm-message'
                                          ),
                                          onCancel: () => {},
                                          onConfirm: () => {
                                            const data = {
                                              hash: row.hash
                                            }

                                            const apiResponse = api

                                              .post('delete-file', data)

                                              .then(response => {
                                                if (response.code === 200) {
                                                  const index =
                                                    parent.form.model[
                                                      field.field_name
                                                    ].findIndex(obj => {
                                                      return obj.id === row.id
                                                    })

                                                  parent.form.model[
                                                    field.field_name
                                                  ].splice(
                                                    index,

                                                    1
                                                  )
                                                }
                                              })

                                            _this.$emit('close')

                                            return apiResponse
                                          }
                                        })
                                      }
                                    }
                                  ]
                                }
                              }
                            )

                            view.modal({
                              component: 'DynamicList',
                              props: {
                                res,
                                params: {
                                  paginated: false,
                                  filterable: false,
                                  title: data.form.form_name,
                                  data: data.dataset,
                                  columns
                                },
                                model: {}
                              }
                            })
                          }
                        })
                      }
                    }

                    hub = hub[field.field_type] || (() => null)

                    hub()
                  }
                },
                elements: [
                  {
                    if: _this =>
                      _this.form.type === 'EDIT_FORM' &&
                      !_this.form.hideSubmitButton,
                    component: 'b-button',
                    label: view.$t('button-submit'),
                    class: 'is-primary',
                    loading: false,
                    onClick: _this =>
                      _this.formValidation({
                        model: view.model[this.name].modal,
                        success: result => {
                          view.api({
                            method: 'post',
                            module: this,
                            endPoint: tab.view.submit_flow,
                            body: {
                              ...result.payload,
                              form_slug: tab.view.form
                            },
                            callback: async res => {
                              res = res.actions
                                ? res.actions[Object.keys(res.actions)[0]]
                                    .route_response.content
                                : res

                              if (result.upload.length > 0) {
                                await Promise.all(
                                  result.upload.map(async file => {
                                    await uploadDocumentNew(
                                      file.value,
                                      res.id,
                                      {
                                        model: view.model[this.name].model,
                                        ...file.properties
                                      }
                                    )
                                  })
                                )
                              }

                              view.params[this.name].res = res

                              _this.form.submitted = true
                              if (
                                tab.view.props.endPoints &&
                                tab.view.props.endPoints.list
                              ) {
                                view.$router.push(tab.view.props.endPoints.list)
                              } else {
                                _this.handles.onCreate(_this)
                              }
                            }
                          })
                        }
                      })
                  }
                ]
              }
            }
          }
        : {
            ...tab,
            disabled: tab.view.props.disabled || false,
            view: {
              ...tab.view,
              component: 'DynamicList',
              props: {
                res: {},
                model: tab.view.props.model || {
                  search: {}
                },
                conditionExpression: tab.view.props.conditionExpression,
                handles: {
                  ...list.handles(view),

                  onCreate: _this => {
                    list.name = 'tabs'
                    const layoutStore = useLayoutStore()

                    list.addSelectedRow = layoutStore.addSelectedRow // needs review
                    list.getSelectedRow = layoutStore.getSelectedRow // needs review

                    if (!view.model[this.name].search)
                      view.model[this.name].search = {}

                    if (view.model[this.name].sort_by) {
                      view.model[this.name].activeTab.view.props.model.sort_by =
                        view.model[this.name].sort_by
                    }

                    view.api({
                      module: this,
                      endPoint: tab.view.flow,
                      method: 'post',
                      body: {
                        form: tab.view.form,
                        id: view.$route.params.id,
                        page_tab_id: view.model[this.name].activeTab.id,
                        ...view.model[this.name].activeTab.view.props.model,
                        search: view.$handleQueryParams(
                          view.model[this.name].search
                        )
                      },
                      callback: res => {
                        res =
                          res.dataFake ||
                          res.actions[Object.keys(res.actions)[0]]
                            .route_response.content

                        view.model[this.name].model = res.form.form_model

                        view.model[this.name].activeTab.view.formButtons =
                          res.form.buttons

                        list.handles(view).table(res)
                      }
                    })
                  },

                  sort: (col, order) => {
                    view.model[this.name].sort_by = `${col}.${order}`

                    view.params[this.name].tabs
                      .find(
                        tab =>
                          tab.id === Number(view.model[this.name].activeTab.id)
                      )
                      .view.props.handles.onCreate()
                  },

                  filterInput: () => {
                    view.model[this.name].page = 1
                    clearTimeout(this.typingTimer)

                    this.typingTimer = setTimeout(() => {
                      view.params[this.name].tabs
                        .find(
                          tab =>
                            tab.id ===
                            Number(view.model[this.name].activeTab.id)
                        )
                        .view.props.handles.onCreate()
                    }, 500)
                  },

                  filterInputClear: field => {
                    if (!view.model[this.name].search[field.name]) return

                    view.model[this.name].search[field.name] = undefined

                    view.params[this.name].tabs
                      .find(
                        tab =>
                          tab.id === Number(view.model[this.name].activeTab.id)
                      )
                      .view.props.handles.onCreate()
                  },

                  cleanSearch: () => {
                    view.model[this.name].search = {}

                    view.params[this.name].tabs
                      .find(
                        tab =>
                          tab.id === Number(view.model[this.name].activeTab.id)
                      )
                      .view.props.handles.onCreate()
                  }
                },
                elements: [
                  {
                    field: 'actions',
                    searchable: false,
                    class: 'list-actions',
                    content: tab.view.actions
                      .map(action => ({
                        if: () => true,
                        sequency: tab.id,
                        render: ({ _this, row, field }) => {
                          const type = row.changed ? 'is-success' : 'is-primary'

                          return {
                            component: 'b-button',
                            content: {
                              element: 'b-icon',
                              title: view.$i18n.t(action.label),
                              icon: action.icon
                            },
                            class: `${type} is-small rounded-icons`
                          }
                        },
                        onClick: ({ _this, row }) =>
                          view.modal({
                            component: 'DynamicForm',
                            props: {
                              res: null,
                              model: {},
                              params: {
                                title: action.label,
                                forms: [action.form]
                              },
                              hasCard: 'true',
                              handles: {
                                api: view.api,
                                onCreate: _this =>
                                  view.api({
                                    endPoint:
                                      action.flow || 'authenticated-forms',
                                    method: 'post',
                                    body: {
                                      form: action.form,
                                      id: row.id
                                    },
                                    callback: res => {
                                      res = res.actions
                                        ? res.actions[
                                            Object.keys(res.actions)[0]
                                          ].route_response.content
                                        : res

                                      _this.formBuilder({
                                        formDependency: action.form,
                                        runDependencyEndpoint:
                                          tab.view.props.endPoints
                                            ?.runDependency,
                                        ...res,
                                        fields: res.form
                                      })

                                      if (res.form.warnings) {
                                        _this.form.warnings = res.form.warnings
                                      }
                                    }
                                  })
                              },
                              elements: [
                                {
                                  component: 'b-button',
                                  label: 'button-close',
                                  onClick: _this => _this.$emit('close')
                                },
                                {
                                  if: () => action.type !== 'view',
                                  component: 'b-button',
                                  label: view.$t('button-submit'),
                                  class: 'is-primary',
                                  loading: false,
                                  onClick: _this => {
                                    _this.formValidation({
                                      action,
                                      model: _this.model,
                                      success: result => {
                                        view.api({
                                          endPoint: action.submit_flow,
                                          method: 'post',
                                          body: {
                                            ...result.payload,
                                            form_slug: action.form
                                          },
                                          callback: async res => {
                                            res = res.actions
                                              ? res.actions[
                                                  Object.keys(res.actions)[0]
                                                ].route_response.content
                                              : res

                                            _this.$emit('close')

                                            if (result.upload.length > 0) {
                                              const formModel = view.model[
                                                this.name
                                              ].activeTab.model
                                                ? view.model[this.name]
                                                    .activeTab.model
                                                : view.model[this.name].model

                                              await Promise.all(
                                                result.upload.map(
                                                  async file => {
                                                    await uploadDocumentNew(
                                                      file.value,
                                                      res.id,
                                                      {
                                                        model: formModel,
                                                        ...file.properties
                                                      }
                                                    )
                                                  }
                                                )
                                              )
                                            }

                                            view.params[this.name].tabs
                                              .find(
                                                tab =>
                                                  tab.id ===
                                                  Number(
                                                    view.model[this.name]
                                                      .activeTab.id
                                                  )
                                              )
                                              .view.props.handles.onCreate()

                                            view.toast({
                                              type: 'is-success',
                                              message: res.message
                                            })
                                            if (action.refresh_page) {
                                              location.reload()
                                            }
                                          }
                                        })
                                      }
                                    })
                                  }
                                }
                              ]
                            }
                          })
                      }))
                      .concat(
                        (tab.view.props.buttons || []).map(action => ({
                          if: ({ row }) =>
                            action.feature
                              ? (view.$checkFeature(action.feature, 'v1') &&
                                  action.feature !==
                                    'notification-data-builder-list-action-download' &&
                                  !row.deleted_at) ||
                                (view.$checkFeature(action.feature, 'v1') &&
                                  action.feature ===
                                    'notification-data-builder-list-action-download' &&
                                  row.attachments !== null &&
                                  row.attachments !== '')
                              : true,
                          sequency: tab.id,
                          component: 'b-button',
                          content: {
                            element: 'b-icon',
                            title: view.$i18n.t(action.title),
                            icon: action.icon
                          },
                          class: `is-primary is-small rounded-icons ${action.errorClass}`,
                          onClick: ({ _this, row }) =>
                            _this.handles.setAction({
                              dialog: true,
                              type: action.type,
                              title: view.$i18n.t(action.messageTitle),
                              message: view.$i18n.t(action.message),
                              endpoint: action.endpoint,
                              params: { id: row.id },
                              download: action.download ?? false
                            })
                        }))
                      )
                  },
                  {
                    element: 'boolean',
                    content: [
                      {
                        if: () => true,
                        render: ({ _this, row, field }) => {
                          let active = 'active-cell'
                          if (parseInt(row[field]) === 0)
                            active = 'red-dot active-cell'
                          if (parseInt(row[field]) === 1)
                            active = 'green-dot active-cell'
                          return {
                            component: 'span',
                            class: active
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
    )

    view.api({
      endPoint:
        tabs[0].view.props.handleTabConditionsUrl || 'handle-tab-conditions',
      method: 'post',
      body: {
        id: view.$route.params.id,
        form: tabs[0].view.form
      },
      callback: res => {
        res = res.actions
          ? res.actions[Object.keys(res.actions)[0]].route_response.content
          : res

        tabs.forEach((tab, index) => {
          if (tab.view.props.conditionExpression) {
            tabs[index].visible = this.evaluateConditionExpression(
              tab.view.props.conditionExpression,
              res.model_info
            )
          } else if (tab.conditions.length > 0) {
            tab.conditions.forEach((tabCondition, c) => {
              const model = tabCondition.form_field.form_model.name
              const fieldName = tabCondition.form_field.extended_field
                ? tabCondition.form_field.extended_field_conn.name
                : tabCondition.form_field.field_model_name
              const fieldValue = tabCondition.field_value

              // if it is a relation
              if (res.model_info[model] && res.model_info[model].length > 0) {
                tabs[index].visible = false
                res.model_info[model].forEach((entry, i) => {
                  if (String(entry[fieldName]) === fieldValue) {
                    tabs[index].visible = true
                    return true
                  }
                })
              } else if (!res.model_info[model]) {
                tabs[index].visible = false
              } else if (
                res.model_info[model] &&
                String(res.model_info[model][fieldName]) !== fieldValue
              ) {
                tabs[index].visible = false
              }
            })
          }

          if (tabs[index].visible === false) {
            return
          }

          if (tab.company_types.length === 0) {
            return
          }
          const companyTypeHasTab = tab.company_types.some(type =>
            res.company_types.includes(type)
          )

          tabs[index].visible = companyTypeHasTab
        })
      }
    })

    view.$set(view.params[this.name], 'tabs', tabs)
    view.$set(view.model[this.name], 'activeTab', tabs[0])
    view.$set(view.model[this.name], 'activeTab', tabs[0])
    view.$set(view.model[this.name], 'activeTabIndex', 0)
    view.$set(view.model[this.name], 'dowpdown', false)

    if (
      view.page.max_tabs_for_display !== '' &&
      tabs.length > view.page.max_tabs_for_display
    ) {
      view.$set(view.model[this.name], 'dowpdown', true)
    }
  },

  evaluateConditionExpression(conditionExpression, data) {
    if ('type' in conditionExpression) {
      if (conditionExpression.type === 'AND') {
        return conditionExpression.conditions.every(subCondition =>
          this.evaluateConditionExpression(subCondition, data)
        )
      }
      if (conditionExpression.type === 'OR') {
        return conditionExpression.conditions.some(subCondition =>
          this.evaluateConditionExpression(subCondition, data)
        )
      }
    }

    const model = conditionExpression.field.split('.')[0]
    const field = conditionExpression.field.split('.')[1]

    const dataModel = data[model]
    if (!dataModel) {
      return false
    }

    if (Array.isArray(dataModel)) {
      return dataModel.some(model => {
        return this.compare(
          model[field],
          conditionExpression.value,
          conditionExpression
        )
      })
    }

    if (Array.isArray(dataModel[field])) {
      return dataModel[field].some(fieldValue => {
        return this.compare(
          fieldValue,
          conditionExpression.value,
          conditionExpression
        )
      })
    }

    return this.compare(
      dataModel[field],
      conditionExpression.value,
      conditionExpression
    )
  },

  compare(A, B, condition) {
    let operator = () => false

    const operators = {
      // eslint-disable-next-line eqeqeq
      '==': (a, b) => a == b,
      '===': (a, b) => a === b,
      // eslint-disable-next-line eqeqeq
      '!=': (a, b) => a != b,
      '!==': (a, b) => a !== b,
      '>': (a, b) => a > b,
      '<': (a, b) => a < b,
      '>=': (a, b) => a >= b,
      '<=': (a, b) => a <= b,
      all: (a, b) => !!a
    }

    switch (condition.operator) {
      case '==':
      case '===':
      case '!=':
      case '!==':
      case '>':
      case '<':
      case '>=':
      case '<=':
      case 'all':
        operator = () => operators[condition.operator](A, B)
        break
      case 'empty':
        operator = () => !A
        break
      case 'filled':
        operator = () => A
        break
      default:
        break
    }

    return operator()
  },

  get(view) {
    view.api({
      module: this,
      endPoint: view.model.tabs.activeTab.view.flow || 'authenticated-forms',
      method: 'post',
      body: {
        form: view.model.tabs.activeTab.view.form,
        id: view.$route.params.id,
        page_tab_id: view.model[this.name].activeTab.id
      },
      callback: res => {
        res = res.actions
          ? res.actions[Object.keys(res.actions)[0]].route_response.content
          : res

        view.model[this.name].model = res.form.form_model

        list.handles(view).table(res)
      }
    })
  },

  handles(view) {
    return {
      onSubmit: _this => {
        _this.formValidation({
          fields: null,
          success: result => {
            view.api({
              method: 'post',
              module: this,
              endPoint: view.model.tabs.activeTab.view.submit_flow,
              body: {
                ...view.$route.params,
                ...result.payload,
                ...view.params[this.name].aditionalParams,
                form_slug: view.model.tabs.activeTab.view.form
              },
              errorCallback: error => {
                _this.isLoading = false
              },
              callback: async res => {
                res = res.actions
                  ? res.actions[Object.keys(res.actions)[0]].route_response
                      .content
                  : res

                if (result.upload.length > 0) {
                  await Promise.all(
                    result.upload.map(async file => {
                      await uploadDocumentNew(
                        file.value,
                        view.$route.params.id,
                        {
                          model: view.model[this.name].model,
                          ...file.properties
                        }
                      )
                    })
                  )
                }

                if (this.name && view.params[this.name]) {
                  view.params[this.name].res = res
                }

                _this.form.submitted = !_this.isSubmitAllSteps

                if (res.message && !res.code) {
                  view.toast({
                    type: 'is-success',
                    message: res.message
                  })
                }

                switch (true) {
                  case view.params[this.name].redirectAfterSubmit !== '' &&
                    view.params[this.name].redirectAfterSubmit === '$next':
                    view.handles[this.name].goToNextTab()
                    break
                  case view.params[this.name].redirectAfterSubmit !== '' &&
                    view.params[this.name].redirectAfterSubmit === '$reload':
                    view.params[this.name].tabs
                      .find(
                        tab =>
                          tab.id === Number(view.model[this.name].activeTab.id)
                      )
                      .view.props.handles.onCreate(_this)

                    view.toast({
                      type: res.error ? 'is-danger' : 'is-success',
                      message: view.$t(res.message || res.error?.message || res)
                    })

                    break
                  case view.params[this.name].redirectAfterSubmit !== '':
                    const [pathPart, queryPart] =
                      view.params[this.name].redirectAfterSubmit.split('?')

                    const processedPath = pathPart
                      .split('/')
                      .map(path =>
                        path.includes(':')
                          ? view.$route.params[path.slice(1)]
                          : path
                      )
                      .join('/')

                    if (!queryPart) {
                      view.$router.push(processedPath)
                      break
                    }

                    const processedQuery = queryPart
                      .split('&')
                      .map(query => {
                        const [key, value] = query.split('=')
                        return value.includes(':')
                          ? `${key}=${view.$route.params[value.slice(1)]}`
                          : query
                      })
                      .join('&')

                    view.$router.push(`${processedPath}?${processedQuery}`)
                    break
                  case view.modules[this.name].props.redirect !== undefined:
                    const redirectTo = view.modules[this.name].props.redirect

                    if (redirectTo === '$reload') {
                      this.init(view)
                      this.handles(view).onCreate(_this)

                      return
                    }

                    view.$router.push(redirectTo)
                    break
                  default:
                    break
                }
              }
            })
          }
        })
      },

      onChange: tabId => {
        view.model[this.name].activeTab =
          view.params[this.name].tabs[Number(tabId)]

        view.model[this.name].activeTabIndex = tabId
      },

      goToNextTab: () => {
        let next = view.model[this.name].activeTabIndex + 1

        while (next < view.params[this.name].tabs.length) {
          if (view.params[this.name].tabs[next].visible) {
            break
          }
          next++
        }

        view.model[this.name].activeTab = view.params[this.name].tabs[next]
        view.model[this.name].activeTabIndex = next

        view.params[this.name].tabs[next].disabled = false
      },

      buttonClick: button => {
        if (button.value === '$next') {
          view.handles[this.name].goToNextTab()
          return
        }

        return view.modal({
          component: 'DynamicForm',
          props: {
            res: null,
            model: {},
            params: {
              title: button.label,
              forms: [button.form]
            },
            hasCard: 'true',
            handles: {
              api: view.api,
              onCreate: _this =>
                view.api({
                  endPoint: button.flow || 'authenticated-forms',
                  method: 'post',
                  body: {
                    form: button.form
                  },
                  callback: res => {
                    res = res.actions
                      ? res.actions[Object.keys(res.actions)[0]].route_response
                          .content
                      : res

                    view.model[this.name].activeTab.model = res.form.form_model

                    _this.formBuilder({
                      formDependency: button.form,
                      ...res,
                      fields: res.form
                    })

                    if (res.form.warnings) {
                      _this.form.warnings = res.form.warnings
                    }
                  }
                })
            },
            elements: [
              {
                component: 'b-button',
                label: 'button-close',
                onClick: _this => _this.$emit('close')
              },
              {
                component: 'b-button',
                label: view.$t('button-submit'),
                class: 'is-primary',
                loading: false,
                onClick: _this => {
                  _this.formValidation({
                    button,
                    model: _this.model,
                    success: result => {
                      view.api({
                        endPoint: button.submit_flow,
                        method: 'post',
                        body: {
                          ...result.payload,
                          page_tab_id: view.model[this.name].activeTab.id,
                          form_slug: button.form
                        },
                        callback: async res => {
                          res = res.actions
                            ? res.actions[Object.keys(res.actions)[0]]
                                .route_response.content
                            : res

                          _this.$emit('close')

                          if (result.upload.length > 0) {
                            await Promise.all(
                              result.upload.map(async file => {
                                await uploadDocumentNew(file.value, res.id, {
                                  model:
                                    view.model[this.name].activeTab.model ??
                                    view.model[this.name].model,
                                  ...file.properties
                                })
                              })
                            )
                          }

                          view.params[this.name].tabs
                            .find(
                              tab =>
                                tab.id ===
                                Number(view.model[this.name].activeTab.id)
                            )
                            .view.props.handles.onCreate()

                          view.toast({
                            type: res.error ? 'is-danger' : 'is-success',
                            message: view.$t(res.message || res.error.message)
                          })

                          if (button.refresh_page) {
                            location.reload()
                          }
                        }
                      })
                    }
                  })
                }
              }
            ]
          }
        })
      }
    }
  }
}
