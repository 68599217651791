export const config = {
  baseUrl: 'https://c8-api.circul8.eu/',
  ApiUrl: 'https://c8-api.circul8.eu/api/',
  storageUrl: 'https://c8-api.circul8.eu/storage/',
  chunkUploadUrl: 'https://chunk-upload.circul8.eu/api',
  websocketsAppKey: 'e81be915-2742-4942-a243-6ef75ebe77a9',
  websocketsServer: 'sockets.circul8.eu',
  appsModalVue3: [
    /* 'ZJutNWnxGQIFf8lLb0x3zYPIjXFjKSPf', // ERP NO
    '4JzDXpKGfTYwFDQYBhLAHLluopy53521' // ERP Italy */
  ],
  appsToRedirectVue3: [
    '29h9lguSsQVUk5EEqhRo6RljQnfrwO17', // ABREE
    'bEPiUABVEk8Rl1uZkM8P2TFy1QDmKj2F', // ERP Finland
    'Kfsgn48pxcHAMUFTS1LCCkxmT3zXKdvu', // ERP Brasil packaging
    'ExuADrgKgwVdzUBfddPVH5tJfMOGA6ei', // ERP Ireland
    '4JzDXpKGfTYwFDQYBhLAHLluopy53521', // ERP Italy
    'ZJutNWnxGQIFf8lLb0x3zYPIjXFjKSPf', // ERP NO
    'V6uzLb94ygP9JaXzTDrW5IGpmDNDJme1', // LB packaging
    'J8qZMl6Y0ObBC4AZus6LiGxqEhX0Mz28', // DS Entsorgung
    'louxK4OPJbqrCz5oWljijfo0ALxeJZwg', // ERP ES TM
    'DmNbyDQ3fpsRhV44j7Zw2cCOJNnjIpCz' // AMAZON
  ],
  vue3URL: 'https://new.circul8.world/'
}
