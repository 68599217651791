import omit from 'lodash/omit'
import inflateTableForm from './inflateTableForm'
import inflateForm from './inflateForm'
import forms from './forms'
import { uploadDocumentNew } from '@/services/document'

export default {
  init(view) {
    view.params[this.name] = view.$route.params
  },

  handles(view) {
    return {
      getActiveStepProps: (step, callback = () => {}) => {
        const usesMatch = {
          REGULAR_FORM: () => forms,
          OVERVIEW_FORM: () => forms,
          EDIT_FORM: () => forms,
          INFLATE_LIST_FORM: () => inflateForm,
          INFLATE_TABLE_FORM: () => inflateTableForm
        }

        const moduleHandles = usesMatch[step.form.type]()

        return {
          res: null,
          model: {},
          params: {
            title: '',
            forms: [step.form.slug]
          },
          handles: {
            // api: view.api,
            ...moduleHandles.handles(view),
            onCreate: _this => {
              view.api({
                endPoint: step.flow || 'authenticated-forms',
                method: 'post',
                body: {
                  form: step.form.slug,
                  declaration_step_code: step.code,
                  ...view.$route.params
                },
                callback: res => {
                  res = res.actions
                    ? res.actions[Object.keys(res.actions)[0]].route_response
                        .content
                    : res

                  this.name = 'form' // TODO: to be refactored!

                  view.model[this.name].model = res.form.form_model

                  _this.form.name = res.form.form_name
                  _this.form.slug = res.form.form_slug
                  _this.form.type = res.form.form_type
                  _this.form.dataset = res.dataset
                  _this.form.buttons = res.form.buttons

                  _this.formBuilder({
                    formDependency: step.form.slug,
                    ...res,
                    fields: res.form.fields
                  })

                  callback()
                }
              })
            },
            onSubmit: (_this, button) => {
              _this.flagDisable = true

              view.params[this.name].transition = view.$route.params.transition

              _this.formValidation({
                success: result => {
                  view.api({
                    method: 'post',
                    module: this,
                    endPoint: step.submit_flow,
                    body: {
                      ...omit(
                        view.params[this.name],
                        'storeFields',
                        'storeForms',
                        'isMultiStep',
                        'res',
                        'component',
                        'forms',
                        'buttons',
                        'aditionalParams'
                      ),
                      ...view.$route.params,
                      ...result.payload,
                      step: step.code,
                      form: step.form.slug,
                      form_slug: step.form.slug // this will be removed
                    },
                    callback: async res => {
                      res = res.actions
                        ? res.actions[Object.keys(res.actions)[0]]
                            .route_response.content
                        : res

                      if (result.upload.length > 0) {
                        await Promise.all(
                          result.upload.map(async file => {
                            await uploadDocumentNew(
                              file.value,
                              res.declaration_status_id,
                              file.properties
                            )
                          })
                        )
                      }

                      view.toast({
                        type: 'is-success',
                        message: res.message
                      })

                      _this.flagDisable = false

                      view.$router.push({ name: view.$route.name })
                    }
                  })
                }
              })
            }
          },
          elements: [
            {
              if: _this =>
                !_this.form.submitted &&
                ['REGULAR_FORM', 'EDIT_FORM'].includes(_this.form.type),
              component: 'b-button',
              label: view.$t('button-submit'),
              class: 'is-primary materials-modal-footer-buttons',
              loading: false,
              onClick: _this => _this.handles.onSubmit(_this)
            }
          ]
        }
      }
    }
  },

  elements(view) {
    return [
      {
        if: _this => true,
        disabled: _this => _this.allStepsCompleted,
        component: 'b-button',
        label: view.$t('button-submit'),
        class: 'is-primary materials-modal-footer-buttons',
        loading: view.isLoading[this.name],
        onClick: _this =>
          view.dialog({
            type: 'is-info',
            hasIcon: true,
            title: view.$t('declaration-steps-submit-title'),
            message: view.$t('declaration-steps-submit-message'),
            onCancel: () => {},
            onConfirm: () => _this.handles.onSubmit(_this)
          })
      }
    ]
  }
}
